
const TABS_ITEMS = document.querySelectorAll('.tabs-item');
const TABS = document.querySelectorAll('.tab');

if (TABS_ITEMS && TABS) {

  let clearActives = () => {
    TABS_ITEMS.forEach(elem => {
      elem.classList.remove('active');
    })
    TABS.forEach(elem => {
      elem.classList.remove('active');
    })
  }

  TABS_ITEMS.forEach((elem, index) => {
    elem.addEventListener('click', () => {
      clearActives();
      elem.classList.add('active');
      TABS[index].classList.add('active');
    })
  })
}