window.onload = function () {
  let map = document.querySelector('#map');
  if (map) {
    ymaps.ready(init);
    function init() {
      var myMap = new ymaps.Map("map", {
        center: [60.028928, 30.330511],
        zoom: 15
      },
        myPlacemark = new ymaps.Placemark([60.028928, 30.330511], {
        }, {
          iconLayout: 'default#image',
          iconImageHref: './img/marker.svg',
          iconImageSize: [51, 70],
          iconImageOffset: [-25, -35]
        }),
      );
      myMap.geoObjects.add(myPlacemark);
    }
  }

}