import Swiper, { Navigation, Scrollbar } from 'swiper';


const sliders = document.querySelectorAll('.slider-container');


if (sliders) {
  sliders.forEach(elem => {
    new Swiper(elem, {
      modules: [Navigation, Scrollbar],
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.slider-button-next',
        prevEl: '.slider-button-prev',
      },

      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  })
}

var sliderWorks = new Swiper('.works__slider', {
  modules: [Navigation, Scrollbar],
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.works  .bar__arrow-next',
    prevEl: '.works  .bar__arrow-prev',
  },
})

let nextWork = document.querySelector('.works .bar__arrow-next');
let prevWork = document.querySelector('.works .bar__arrow-prev');

if (nextWork && prevWork) {
  nextWork.addEventListener('click', () => {
    sliderWorks.slideNext();
  });
  prevWork.addEventListener('click', () => {
    sliderWorks.slidePrev();
  });
}


var sliderMain = new Swiper('.main-slider', {

  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,

})


let nextMain = document.querySelector('.main-section  .bar__arrow-next');
let prevMain = document.querySelector('.main-section  .bar__arrow-prev');

if (nextWork && prevWork) {
  nextMain.addEventListener('click', () => {
    sliderMain.slideNext();
  });
  prevMain.addEventListener('click', () => {
    sliderMain.slidePrev();
  });
}
