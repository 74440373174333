const BURGER = document.querySelector('.burger');
const MENU = document.querySelector('.mob-menu');
const CLOSE = document.querySelector('.mob-menu__close');

if (BURGER && MENU && CLOSE) {
  BURGER.addEventListener('click', () => {
    MENU.classList.add('active')
  })
  CLOSE.addEventListener('click', () => {
    MENU.classList.remove('active')
  })
}